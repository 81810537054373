<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All Trainers</h4>
                            </div>
                            <div class="col-md-9">
                            </div>
                            <div class="col-md-1">
                                <b-button variant="success">
                                    <i class="ri-file-excel-2-line align-middle mr-2"></i> Export
                                </b-button>
                                <b-modal
                                        id="modal-delete"
                                        centered
                                        title="Confirm Delete"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <center>  <span  >Are you sure you want to Delete?</span></center>
                                    <div class="text-center">
                                        <button
                                                class="btn btn-danger w-md waves-effect waves-light mt-4"
                                                @click="hideModal"
                                        >No</button>
                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4 mx-3"
                                                type="submit"
                                                @click="del(selectedID),hideModal()"
                                        >Yes</button>
                                    </div>


                                </b-modal>
                                <b-modal
                                        id="view-trainee"
                                        v-if="this.selectedID"
                                        centered
                                        size="xl"
                                        title="view Trainee"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <form >
                                        <div class="row form-group">
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Name: </div>
                                                    <div class="mr-3 align-self-center h6"> {{this.selectedID.name_and_surname}}</div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Region :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedID.region.name}} </div>
                                                </div>

                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Site :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedID.institution}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">S/N  :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedID.sn}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Responsibility :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedID.responsibility}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Phone :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedID.phone}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Email  :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedID.email}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <div class="d-inline-flex">
                                                    <div class="mr-3 align-self-center h6">Date of training :</div>
                                                    <div class="mr-3 align-self-center h6">{{this.selectedID.date_of_training}} </div>
                                                </div>
                                            </div>

                                        </div>
                                        <!-- <button class="btn btn-primary" href=""  type="submit">Edit</button> -->
                                    </form>

                                </b-modal>
                                <b-modal
                                        id="update-trainee"
                                        centered
                                        size="xl"
                                        title="view Trainee"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <form @submit.prevent="updateTrainee" ref="form">
                                        <div class="row" v-if="editItem">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label >Name and Surname</label>
                                                    <input
                                                            type="text"
                                                            placeholder="Name"
                                                            class="form-control"
                                                            v-model="editItem.name_and_surname"
                                                    />

                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Region </label>
                                                    <multiselect  v-model="editItem.region" label="name" :options="regionData" :multiple="false" placeholder="Select Region"></multiselect>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label >Site</label>
                                                    <input
                                                            type="text"
                                                            v-model="editItem.institution"
                                                            placeholder="Site"
                                                            class="form-control"
                                                    />

                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > S/N</label>
                                                    <input
                                                            type="text"
                                                            v-model="editItem.sn"
                                                            placeholder="S/N "
                                                            class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Responsibility</label>
                                                    <input
                                                            type="text"
                                                            placeholder="Responsibility "
                                                            class="form-control"
                                                            v-model="editItem.responsibility"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Phone</label>
                                                    <input
                                                            v-model="editItem.phone"
                                                            type="number"
                                                            placeholder="Phone "
                                                            class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Email</label>
                                                    <input
                                                            type="email"
                                                            v-model="editItem.email"
                                                            placeholder="Email "
                                                            class="form-control"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label > Date of training</label>
                                                    <date-picker v-model="editItem.date_of_training" range append-to-body lang="en" placeholder="Date of training" confirm></date-picker>
                                                </div>
                                            </div>


                                        </div>
                                        <button class="btn btn-primary text-right" @click="hideModal" type="submit">Update</button>
                                    </form>

                                </b-modal>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="traineeData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"

                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(actions)="{item}">
                                    <b-button class="actionBut" variant="info" v-b-modal.view-trainee v-b-tooltip.hover @click.prevent="view(item)" title="View Details">
                                        <i class="ri-eye-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="success" @click.prevent="edit(item)"  v-b-modal.update-trainee v-b-tooltip.hover title="Edit">
                                        <i class="ri-edit-fill align-middle "></i>
                                    </b-button>
                                    <b-button class="actionBut" variant="danger"  v-b-modal.modal-delete @click.prevent="choosenID(item)"  v-b-tooltip.hover title="Delete">
                                        <i class="ri-delete-bin-2-fill align-middle " ></i>
                                    </b-button>
                                    <!--                                    <span><b-btn @click="editItem(item)">Edit</b-btn></span>-->


                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="lodader__content" v-if="loader">
            <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
        </div>
    </Layout>

</template>

<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";
    import DatePicker from "vue2-datepicker";

    import { tableData } from "./dataTrainers";
    import {axios_delete, axios_get, axios_put} from "../../../../helpers/helper";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View trainers",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout,Multiselect, DatePicker,PageHeader },
        data() {
            return {
                loader: false,
                tableData: tableData,
                traineeData:[],
                trainee_id:"",
                selectedID:"",
                editItem:null,
                TData:[],
                title: "View all trainers",
                items: [
                    {
                        text: "CQI",
                        href: "/"
                    },
                    {
                        text: "View trainees",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                rd:[],
                currentRegion:null,
                sortBy: "",
                sortDesc: false,
                fields: [
                    { key: "sn", sortable: true },
                    { key: "name_and_surname", sortable: true },
                    { key: "region_name", sortable: true },
                    { key: "responsibility", sortable: true },
                    { key: "site.site_name", sortable: true, label: 'Institution' },
                    { key: "email", sortable: true },
                    // { key: "Refresher_date", sortable: true },
                    // { key: "facilityEmail", sortable: true },
                    { key: "date_of_training", sortable: true },
                    { key: "actions", sortable: false },

                ],



            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.traineeData.length;
            }
        },
        watch:{
            'TData': {
                handler:function(o,n){
                    console.log("var changes", o,JSON.parse(JSON.stringify(n)));
                },
                deep:true
            }
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;

            this.countries=JSON.parse(localStorage.getItem('user'))
            console.log(this.countries.country_id)
            this.country_id=this.countries.country_id
            axios_get("/regions",{},(d)=>{
                    console.log(JSON.stringify(d))

                    this.regionData=JSON.parse(JSON.stringify(d))
                    console.log(JSON.parse(JSON.stringify(d)))
                    this.regionData=this.regionData.filter(e=>{return e.country_id==this.country_id})
                    // localStorage.setItem('regions',JSON.stringify(this.regionData))
                    console.log(this.regionData)
                    // this.rd = JSON.stringify(this.regionData);



                axios_get("/trainee",{},(d)=>{
                        console.log(d)
                        this.traineeData=d
                        this.traineeData=d.filter(element=>{
                            return element.region.country_id==this.countries.country_id
                        });
                        this.traineeData=this.traineeData.filter(element=>{
                            element.region_name = element.region.name
                            return element
                        });
                        console.log(this.traineeData)
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                    }
                );



                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                }
            );
        },
        methods: {
            // editItem(item) { console.log(item); },
            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            choosenID(e){
                console.log(e)
                this.selectedID=e.id
                console.log(this.selectedID)
                return this.selectedID

            },
            del(e){
                this.loader=true
                const  index = this.traineeData.indexOf(this.traineeData.find(r=>r.id===e))
                // console.log(e)
                console.log(index)
                this.trainee_id=e
                console.log(this.trainee_id)
                axios_delete("/trainee/"+this.trainee_id,{
                    },
                    (d)=>{
                        console.log(d)
                        this.traineeData.splice(index,1)
                        window.makeToast('Success','Trainee Deleted Successfully','success')
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader=false
                    }
                );
            },
            updateTrainee(){
                this.loader=true
                let dateinfo =this.editItem.date_of_training[0].toLocaleString();
                let dateinfo1 =this.editItem.date_of_training[1].toLocaleString();
                let splitdata = dateinfo.split(" ");
                let splitdata1 = dateinfo1.split(" ");
                this.editItem.date_of_training = splitdata[0] +" - "+splitdata1[0];
                console.log(this.editItem)
                // return;
                //this.TData.region_id=this.currentRegion.id

                axios_put("/trainee/"+this.editItem.id,this.editItem,
                    (d)=>{
                        console.log(d);
                        axios_get("/trainee",{},(d)=>{
                            console.log(d)
                            this.traineeData=this.traineeData.filter(element=>{
                                element.region_name = element.region.name
                                return element
                            });
                                //console.log(this.siteData)
                            },(e)=>{
                                console.log("error",e);
                            },()=>{
                                console.log("finishing");
                            }
                        );
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                        this.loader=false
                    })


            },
            hideModal() {
                this.$refs['my-modal'].hide()
            },
            edit(e){
                console.log(e)
                this.editItem = e
                const spl = this.editItem.date_of_training.split(" - ");
                const d1 = spl[0].split("/").reverse().join("-")
                const d2 = spl[1].split("/").reverse().join("-")
                this.editItem.date_of_training = [new Date(d1),new Date(d2)];
            },
            view(e){
                this.selectedID = e
            },
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left: 15px;
    }

    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }

</style>
